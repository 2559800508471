import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  changeIcon() {
    this.favIcon.href = null;
  }

  public constructor(private titleService: Title) {
   }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {
    let urllink = window.location.href;
    if (urllink.toLowerCase().includes('amazingvideos-mix')) {
      this.titleService.setTitle("AmazingVideos Mix");
      this.changeIcon();
    }
  }

	//title = 'app works!';
}
